<template>
  <v-menu :close-on-content-click="true">
    <template #activator="{ on }">
      <v-btn class="ml-2" icon v-on="on">
        <mf-icon>more_vert</mf-icon>
      </v-btn>
    </template>
    <v-list>
      <history-view :item="item" :modality="modality" />
      <go-to-retailer-dialog :disabled="!item.existsOnRetailer" :item="item" />
      <v-list-item :disabled="!item.canBeEdited" @click="$emit('edit')">
        <v-list-item-title>Editar</v-list-item-title>
      </v-list-item>
      <v-list-item :disabled="!item.canBeDeleted" @click="$emit('delete')">
        <v-list-item-title>Remover</v-list-item-title>
      </v-list-item>
      <v-list-item :disabled="!item.canBeReprocessed" @click="$emit('reprocess')">
        <v-list-item-title>Reprocessar</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { ENUM_MODALITY } from '@/modules/industry/enums'

export default {
  name: 'ContextMenu',
  components: {
    HistoryView: () => import('@/modules/industry/components/HistoryView.vue'),
    GoToRetailerDialog: () => import('@/modules/industry/components/IndustryOfferTable/GoToRetailerDialog.vue')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    modality: {
      type: String,
      default: 'none',
      validator: value => [ENUM_MODALITY.MERCAPROMO, ENUM_MODALITY.MERCATRADE, 'none'].includes(value)
    }
  }
}
</script>
